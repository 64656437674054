@import '../../../../node_modules/@amboss/design-system/build/scss/variables';

.chat-page {
  position: relative;
  width: 100%;
  min-width: 250px;
  max-width: 968px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow: auto;
}

.message-list .chat-header {
  position: sticky;
  top: 0;
  flex-direction: row;
  align-self: center;
  z-index: 100;
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);
  flex-grow: 0;
  top: 0;
  overflow: visible;
}

.message-list {
  position: relative;
  top: 0;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Spacing between messages */
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.message-input-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center; /* Center align the button and input */
}

.message-input {
  position: relative;
  width: -webkit-fill-available;
  width: stretch;
  max-width: min(768px);
  margin: 0 auto;
  padding: 10px;
  background: $colorBackgroundPrimary; /* Light grey background for the input area */
}

.message-input textarea {
  flex-grow: 1;
  font-size: medium;
  padding: 10px 90px 10px 10px;
  border: 1px solid $colorBorderPrimary; /* Light grey border for the textarea */
  border-radius: 10px;
  min-height: 40px; /* Minimum height */
  max-height: 100px; /* Maximum height */
  overflow-y: hidden; /* Hide the scrollbar */
  resize: none;
}

.message-input button {
  z-index: 11;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Apply the animation to your recording button when isRecording is true */
button.recording {
  animation: flash 2s linear infinite;
}

button.scroll-to-bottom {
  position: absolute;
  transform: translateY(-111%);
  margin-bottom: 5px;
  transition:
          opacity 0.3s ease-in-out,
          visibility 0.3s ease-in-out;
  opacity: 1;
  width: 2.5rem;
  height: 2.5rem;
  background: $colorBackgroundPrimary;
  visibility: visible;
}

.scroll-to-bottom.hidden {
  opacity: 0;
  visibility: hidden;
}

@import '../../../../node_modules/@amboss/design-system/build/scss/variables';
@import '../../../../node_modules/@amboss/design-system/build/scss/theming';

@include theming();

:root {
  --text-primary: #111; /* Primary text color */
  --btn-primary: #007bff; /* Vivid blue for primary buttons */
  --btn-primary--hover: #0059ff; /* Vivid blue for primary buttons */
  --btn-secondary: #6c757d; /* Subdued grey for secondary buttons */
  --btn-secondary--hover: #808991; /* Subdued grey for secondary buttons */
  --btn-tertiary: #28a745; /* Green for tertiary actions */
  --btn-tertiary--hover: #14862f; /* Green for tertiary actions */
  --btn-cancel: #dc3545; /* Red for cancel actions */
  --btn-cancel--hover: #a71d2a; /* Red for cancel actions */
  --gray-light: #f8f9fa; /* Very light grey for backgrounds */
  --gray-light-2: #dedede; /* Very light grey for backgrounds */
  --gray-dark: #4f535b; /* Dark grey for secondary text or less important elements */
  --gray-dark-2: #6c6c6c; /* Dark grey for secondary text or less important elements */
  --font-stack: 'Helvetica Neue', sans-serif;
  --heading-font-size: 2rem;
}

.light-theme {
  @include ds-theme-light;
}

.dark-theme {
  @include ds-theme-dark;
}

body {
  height: 0;
  max-width: 1168px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  background-color: $colorBackgroundPrimary;
  font-family: var(--font-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: var(--heading-font-size);
}
